<template>
  <div class="holder">
    <h1>funnelChart、funnelBlock、funnelLabel、funnelTooltip四个参数</h1>
    <h1>
      effect : 动画类型('topIn' || 'bottomIn' || 'leftIn' || 'crossIn') 默认值:
      "" (不填的话默认使用组件自带动画)
    </h1>
    <h1>
      具体参数详情请<a
        href="https://github.com/jakezatecky/d3-funnel"
        target="_blank"
        >点击这里</a
      >
    </h1>
    <h2>
      漏斗图入场topIn
      <span @click="playIn1('funnelChart1')">入场</span>
      <span @click="playOut('funnelChart1')">出场</span>
    </h2>
    <funnel-chart ref="funnelChart1" id="funnelChart1" customClass="c_bar1" />
    <h2>
      漏斗图入场bottomIn
      <span @click="playIn2('funnelChart2')">入场</span>
      <span @click="playOut('funnelChart2')">出场</span>
    </h2>
    <funnel-chart ref="funnelChart2" id="funnelChart2" customClass="c_bar1" />
    <h2>
      漏斗图入场leftIn
      <span @click="playIn3('funnelChart3')">入场</span>
      <span @click="playOut('funnelChart3')">出场</span>
    </h2>
    <funnel-chart ref="funnelChart3" id="funnelChart3" customClass="c_bar1" />
    <h2>
      漏斗图入场crossIn
      <span @click="playIn4('funnelChart4')">入场</span>
      <span @click="playOut('funnelChart4')">出场</span>
    </h2>
    <funnel-chart ref="funnelChart4" id="funnelChart4" customClass="c_bar1" />
    <h2>
      漏斗图入场默认
      <span @click="playIn5('funnelChart5')">入场</span>
      <span @click="playOut('funnelChart5')">出场</span>
    </h2>
    <funnel-chart ref="funnelChart5" id="funnelChart5" customClass="c_bar1" />
  </div>
</template>
<script>
import * as d3 from "d3";
import funnelChart from "@/components/funnelChart/index.vue";
export default {
  data: function () {
    return {
      data2: [
        { label: "Inquiries", value: 5000 },
        { label: "Applicants", value: 2500 },
        { label: "Admits", value: 500 },
        { label: "Deposits", value: 200 },
        { label: "Deposits", value: 100 },
      ],
    };
  },
  components: { funnelChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].funnelChart(this.data2, {
        funnelChart: {
          width: 400,
          height: 400,
          bottomWidth: 0.2,
          inverted: true,
          animate: 0,
        },
        funnelBlock: {},
        funnelLabel: {},
        funnelTooltip: {},
        effect: "topIn", //topIn bottomIn leftIn crossIn
      });
    },
    playIn2(refName) {
      this.$refs[refName].funnelChart(this.data2, {
        funnelChart: {
          width: 400,
          height: 400,
          bottomWidth: 0.2,
          inverted: true,
          animate: 0,
        },
        funnelBlock: {},
        funnelLabel: {},
        funnelTooltip: {},
        effect: "bottomIn", //topIn bottomIn leftIn crossIn
      });
    },
    playIn3(refName) {
      this.$refs[refName].funnelChart(this.data2, {
        funnelChart: {
          width: 400,
          height: 400,
          bottomWidth: 0.2,
          inverted: true,
          animate: 0,
        },
        funnelBlock: {},
        funnelLabel: {},
        funnelTooltip: {},
        effect: "leftIn", //topIn bottomIn leftIn crossIn
      });
    },
    playIn4(refName) {
      this.$refs[refName].funnelChart(this.data2, {
        funnelChart: {
          width: 400,
          height: 400,
          bottomWidth: 0.2,
          inverted: true,
          animate: 0,
        },
        funnelBlock: {},
        funnelLabel: {},
        funnelTooltip: {},
        effect: "crossIn", //topIn bottomIn leftIn crossIn
      });
    },
    playIn5(refName) {
      this.$refs[refName].funnelChart(this.data2, {
        funnelChart: {
          width: 400,
          height: 400,
          bottomWidth: 0.2,
          inverted: true,
          animate: 200,
        },
        funnelBlock: {},
        funnelLabel: {},
        funnelTooltip: {},
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h2 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h1 {
  font-size: 18px;
  color: #9c27b0;
  margin-bottom: 10px;
}
p {
  margin-bottom: 20px;
  color: #555;
}

.c_bar1 {
  background: #ffd918;
  background: -webkit-linear-gradient(bottom right, #ffd918, #e8882d);
  background: -moz-linear-gradient(bottom right, #ffd918, #e8882d);
  background: linear-gradient(to top left, #ffd918, #e8882d);
  font-size: 14px;
  min-height: 100px;
  display: inline-block;
  margin-bottom: 30px;
}
.box {
  margin: 10% auto;
  width: 600px;
  height: 400px;
  background-color: #ddd;
}

.title {
  font-size: 20px;
}

.label {
  font-size: 13px;
}

.polygon {
  fill-opacity: 0.8;
}
</style>

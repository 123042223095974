<template>
  <!-- <div :id="id" :class="customClass"></div> -->
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
// import "./d3-funnel.js";
import D3Funnel from "./d3-funnel.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      yScale: null,
      yDomain: [],
      ease: "easeBack",
      finalPoints: [],
      initialPoints: [],
    };
  },
  methods: {
    async playOut() {
      const that = this;
      // console.log(d3.selectAll('svg'));
      if (that.effect === "topIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(that.duration)
          .delay((d, i) => {
            return i * that.delay;
          })
          .style("opacity", "0");
      } else if (that.effect === "bottomIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(that.duration)
          .delay((d, i) => {
            return (that.data.length - 1 - i) * that.delay;
          })
          .style("opacity", "0");
      } else if (that.effect === "leftIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(that.duration)
          .delay((d, i) => {
            return i * that.delay;
          })
          .attr("transform", `translate(-50,0)`)
          .style("opacity", "0");
      } else if (that.effect === "crossIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(that.duration)
          .delay((d, i) => {
            return i * that.delay;
          })
          .attr("transform", (d, i) => {
            if (i % 2 === 1) {
              return `translate(-50,0)`;
            } else {
              return `translate(50,0)`;
            }
          })
          .style("opacity", "0");
      } else {
        d3.selectAll(`#${that.id} svg`)
          .transition()
          .duration(that.duration)
          .delay((d, i) => {
            return i * that.delay;
          })
          .style("opacity", "0");
      }

      // this.svg.transition().duration(400).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    funnelChart(
      data,
      {
        // x = (d, i) => i, // given d in data, returns the (ordinal) x-value
        // y = (d) => d, // given d in data, returns the (quantitative) y-value
        // z = () => 1,
        // marginTop = 40, // the top margin, in pixels
        // marginRight = 0, // the right margin, in pixels
        // marginBottom = 30, // the bottom margin, in pixels
        // marginLeft = 40, // the left margin, in pixels
        // width = 640, // the outer width of the chart, in pixels
        // height = null, // the outer height of the chart, in pixels
        // xDomain, // an array of (ordinal) x-values
        // xRange = [marginLeft, width - marginRight], // [left, right]
        // yDomain, // [ymin, ymax]
        // yRange = [height - marginBottom, marginTop], // [bottom, top]
        // zDomain, // array of z-values
        // zPadding = 0.05,
        // xPadding = 0.1, // 柱子的比例
        duration = 400, //动画持续时长
        delay = 100, //元素之间间隔时长
        funnelChart = {},
        funnelBlock = {},
        funnelLabel = {},
        funnelTooltip = {},
        effect = "", //topIn bottomIn leftIn crossIn
      } = {}
    ) {
      // if (effect === "topIn") {
      //   $("#funnel").addClass("none");
      // }
      const that = this;
      this.duration = duration;
      this.delay = delay;
      this.effect = effect;
      this.data = data;
      const options = {
        chart: funnelChart,
        block: funnelBlock,
        label: funnelLabel,
        tooltip: funnelTooltip,
      };

      const chart = new D3Funnel("#" + that.id);
      chart.draw(data, options);

      if (effect === "topIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(0)
          .delay(0)
          .style("opacity", "0");

        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(duration)
          .delay((d, i) => {
            return i * delay;
          })
          .style("opacity", "1");
      } else if (effect === "bottomIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(0)
          .delay(0)
          .style("opacity", "0");

        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(duration)
          .delay((d, i) => {
            return (data.length - 1 - i) * delay;
          })
          .style("opacity", "1");
      } else if (effect === "leftIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(0)
          .delay(0)
          .attr("transform", `translate(-50,0)`)
          .style("opacity", "0");

        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(duration)
          .delay((d, i) => {
            return i * delay;
          })
          .attr("transform", `translate(0,0)`)
          .style("opacity", "1");
      } else if (effect === "crossIn") {
        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(0)
          .delay(0)
          .attr("transform", (d, i) => {
            if (i % 2 === 1) {
              return `translate(-50,0)`;
            } else {
              return `translate(50,0)`;
            }
          })
          .style("opacity", "0");

        d3.selectAll(`#${that.id} svg g`)
          .transition()
          .duration(duration)
          .delay((d, i) => {
            return i * delay;
          })
          .attr("transform", `translate(0,0)`)
          .style("opacity", "1");
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
.none {
  opacity: 0;
  transform: translate();
}
</style>
